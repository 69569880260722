import React from 'react'
import Dynamic from './Dynamic'
import WorkNav from './WorkNav'
// import NewNav from './NewNav'

function Main() {
  return (
    <div>
        <Dynamic />
        {/* <NewNav /> */}
        <WorkNav />
    </div>
  )
}

export default Main